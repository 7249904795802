function SocialIcons() {
    return (
      <div className="social-icons">
        <img src="/images/github_icon.png" alt="GitHub Icon" />
        <img src="/images/youtube_icon.png" alt="YouTube Icon" />
        <img src="/images/instagram_icon.png" alt="Instagram Icon" />
        <img src="/images/facebook_icon.png" alt="Facebook Icon" />
      </div>
    );
  }
  
  export default SocialIcons;
  