// src/components/ContactSection.js
import React from 'react';

const ContactSection = () => {
  return (
    <section id="contact" className="contact-section">
      <h2>Contact Me</h2>
      <p>Feel free to reach out to me through my social media or email.</p>
    </section>
  );
};

export default ContactSection;
