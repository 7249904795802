// src/App.js
import './App.css';
import React from 'react';
import MyNavbar from './components/Navbar'; // Import the navbar
import HomeSection from './components/HomeSection';
import AboutSection from './components/AboutSection';
import ProjectsSection from './components/ProjectsSection';
import ContactSection from './components/ContactSection';
import SocialIcons from './components/SocialIcons';

// Import the new sections
import Summary from './components/Summary';
import Experience from './components/Experience';
import Skills from './components/Skills';
import Education from './components/Education';

function App() {
  return (
    <>
      <MyNavbar /> {/* Navbar at the top */}
      <h1>Hello, World!</h1>
      <p>Welcome to my software development portfolio, created by Weston Guidero.</p>

      <main>
        <HomeSection />
        <AboutSection />
        <ProjectsSection />
        <ContactSection />
        <SocialIcons />

        {/* New sections added here */}
        <Summary />
        <Experience />
        <Skills />
        <Education />
      </main>
    </>
  );
}

export default App;
