// src/components/HomeSection.js
import React from 'react';

const HomeSection = () => {
  return (
    <section id="home" className="home-section">
      <div className="home-content">
        <h1>Welcome to My Portfolio</h1>
        <p>I am a web developer.</p>
      </div>
    </section>
  );
};

export default HomeSection;
