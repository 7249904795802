// src/components/ProjectsSection.js
import React from 'react';

const ProjectsSection = () => {
  return (
    <section id="projects" className="projects-section">
      <h2>My Projects</h2>
      <p>Here are some of my web development projects.</p>
      <a href="https://imdbdatabaseapp.westonguidero.com" target="_blank" rel="noopener noreferrer">
        Check out my IMDb database app
      </a>
    </section>
  );
};

export default ProjectsSection;
