import React from 'react';

const Experience = () => {
  return (
    <section id="experience">
      <h2>Experience</h2>

      <h3>Software Developer – Freelance (2023-Current)</h3>
      <ul>
        <li>nafsbenefits.com & nationalfamilyservices.com: Fixed a failed custom PHP payment processor plugin leading to lost revenue. Integrated new PHP/API code, transaction keys, and renewed SSL certificates.</li>
        <li>Created staging sites, configured PHP versions & other features via SiteGround.com web hosting.</li>
        <li>Result: Generated record-high $587,400 in revenue, sales, and submissions.</li>
      </ul>

      <h3>Promineo Tech Front Developer (2023-2024)</h3>
      <ul>
        <li>Documented single-page UI using JavaScript fetch, HTTP requests, CRUD operations, React components, and forms.</li>
        <li>Developed and maintained ecommerce and musician websites, implementing frontend functionality and system administration tasks to ensure seamless user experiences.</li>
      </ul>

      <h3>AV/IT Technician - Encore Global | SD Showdown | AVS | Freelance Electronics (2022-Current)</h3>
      <ul>
        <li>Set up and operated media equipment such as computers, audio mixers, PAs, microphones, LCD projectors, and more.</li>
      </ul>

      <h3>Project Coordinator/Asst. Editor-JTN Music | Composers Assistant-DAM & Mark Petrie (2017-2022)</h3>
      <ul>
        <li>Audio Post Production QC & Editing: Published info on cue sheets, sorted scores, and coordinated schedules.</li>
      </ul>
    </section>
  );
};

export default Experience;
