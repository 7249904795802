import React from 'react';

const Summary = () => {
  return (
    <section id="summary">
      <h2>Summary</h2>
      <p>
        I am a passionate software developer specializing in JavaScript and React, with experience building robust back-end systems using Java, Spring Boot, MySQL, MongoDB, Node.js, and Firebase. Proficient in REST APIs, JUnit testing, and object-oriented programming. I helped a law firm recover from revenue loss and achieve record sales by fixing API integration and updating SSL certificates. My work with Promineo Tech and personal projects highlights strengths in full-stack development and problem-solving. Previous roles in AV and music industries furthered my skills in IT troubleshooting, system setup, project coordination, and client relations.
      </p>
    </section>
  );
};

export default Summary;
