import React from 'react';

const Education = () => {
  return (
    <section id="education">
      <h2>Education & Certificates</h2>
      <ul>
        <li>El Camino College - Promineo Tech – Software Development Bootcamp (2023-2024)</li>
        <li>Coursera - Technical Support Fundamentals (2023) & Udemy – AWS, Python, Frontend, etc.</li>
        <li>Berklee College of Music Bachelor of Arts - Writing & Production (2013-2016)</li>
      </ul>
    </section>
  );
};

export default Education;
