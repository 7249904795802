import React from 'react';

const Skills = () => {
  return (
    <section id="skills">
      <h2>Skills</h2>
      <ul>
        <li>Web Development: React, Bootstrap, jQuery, HTML, CSS, JavaScript, Node.js, Express.js, SQL/MySQL, MongoDB, Firebase</li>
        <li>Cloud & DevOps: AWS (EC2, S3, Lambda), Azure, Docker, Kubernetes, Git</li>
        <li>Audio/Video/Games: Wwise, Fmod, Godot, Pro Tools, Reaper, Ableton</li>
        <li>Networking & Libraries: API Integration, AI, Machine Learning, NAS</li>
        <li>Productivity Tools: Microsoft 365, Google Suite, Linux, Windows, macOS, Slack, Asana</li>
      </ul>
    </section>
  );
};

export default Skills;
